import * as React from "react"

const SvgComponent = props => (
  <svg
    width={65}
    height={64}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6439 50.856C18.6805 55.8932 25.3772 58.6666 32.5 58.6666C47.2042 58.6666 59.1666 46.7041 59.1666 31.9997C59.1666 17.2959 47.2042 5.33331 32.5 5.33331C26.2969 5.33331 20.3245 7.50208 15.5873 11.3852L12.6894 8.4872C11.7676 7.56593 10.1907 8.06439 9.96584 9.34784L8.10454 19.9781C7.91138 21.0824 8.87825 22.0412 9.98087 21.8389L20.5349 19.9019C21.8108 19.668 22.3001 18.098 21.3826 17.1811L18.5743 14.3727C22.5137 11.2634 27.4135 9.52759 32.5 9.52759C44.8911 9.52759 54.9724 19.6084 54.9724 31.9997C54.9724 44.3915 44.8911 54.4724 32.5 54.4724C26.4976 54.4724 20.8541 52.1346 16.61 47.8904C12.3653 43.6457 10.0281 38.0022 10.0281 31.9997C10.0281 30.8418 9.08911 29.9028 7.9307 29.9028C6.77228 29.9028 5.83331 30.8418 5.83331 31.9997C5.83331 39.1231 8.60729 45.8193 13.6439 50.856ZM31.6121 23.1232C29.1601 23.4129 27.8659 24.8262 27.8659 26.393C27.8659 27.9426 28.8875 29.0838 31.6121 29.7819V23.1232ZM37.6747 37.2749C37.6747 35.5037 36.704 34.465 33.5368 33.7154V40.6976C36.4486 40.4765 37.6747 39.0971 37.6747 37.2749ZM25.6349 36.3043C26.4527 36.3043 27.0488 36.7298 27.4061 37.6323C27.9679 39.2333 29.1773 40.425 31.6121 40.6804V33.2561L30.9141 33.0855C26.5889 32.081 24.2555 30.1392 24.2555 26.6827C24.2555 22.8506 27.3208 20.3476 31.6121 20.024V17.8612C31.6121 17.265 32.021 16.8905 32.5833 16.8905C33.1279 16.8905 33.5368 17.265 33.5368 17.8612V20.024C37.1639 20.3132 39.9228 22.2379 40.7228 25.0478C40.8081 25.337 40.8425 25.6096 40.8425 25.916C40.8425 26.8356 40.161 27.4661 39.1732 27.4661C38.2879 27.4661 37.7256 26.9719 37.3683 26.1547C36.6531 24.3836 35.427 23.362 33.5368 23.1232V30.2245L34.3539 30.429C38.8325 31.4677 41.2851 33.3071 41.2851 36.9342C41.2851 41.1236 37.8791 43.4738 33.5368 43.7801V45.9258C33.5368 46.5219 33.1279 46.8965 32.5833 46.8965C32.021 46.8965 31.6121 46.5219 31.6121 45.9258V43.7801C27.8488 43.5076 24.8859 41.6516 24.0682 38.8073C23.9834 38.5181 23.9319 38.2112 23.9319 37.9392C23.9319 36.968 24.5962 36.3043 25.6349 36.3043Z"
      fill="white"
    />
  </svg>
)

export default SvgComponent

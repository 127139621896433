import React from "react"
import { Helmet } from "react-helmet"
import { Parallax } from "react-parallax"
import { Link } from "../components/CustomLinks"
import Button from "@material-ui/core/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUniversity,
  faBook,
  faUsers,
  faMapMarkerAlt,
  faChartBar,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons"
import FloatingSearch from "../components/floatingSearch"
import Layout from "../components/layout"
import ColoredHeader from "../components/coloredHeader"
import CircleMoneyIcon from "../../static/icons/CircleMoney"

import atlas_lg_blackwhite from "../../static/background_images/atlas_lg_blackwhite.jpg"
import company_bg from "../../static/background_images/company_bg.jpg"

const FactSheet_2023 = "/documents/Apr_2024_EOG_Website_Fact_Sheet.pdf"

export default function Company(props) {
  return (
    <Layout>
      <Helmet>
        <title>Company – EOG Resources, Inc.</title>
        <body data-page="company" />
      </Helmet>
      <div className="company-wrapper">
        <ColoredHeader
          title="COMPANY"
          header="High Return Organic Growth"
          overlayColor1="#d50000"
          overlayColor2="#da3433"
          lineColor="#f95c4f"
          bgImageSRC={atlas_lg_blackwhite}
        />
        <div className="max-width-container absolute desktop-only">
          <FloatingSearch clear />
        </div>
        <Parallax bgImage={company_bg} strength={300}>
          <section className="max-width-container page company-page company-links-layout">
            <div className="column">
              <div className="company-link">
                <Link to="/company/history/">
                  <FontAwesomeIcon className="bigicon" icon={faBook} />
                </Link>
                <Link to="/company/history/">History</Link>
              </div>
              <div className="company-link">
                <Link to="/company/areas-of-operation/">
                  <FontAwesomeIcon className="bigicon" icon={faMapMarkerAlt} />
                </Link>
                <Link to="/company/areas-of-operation/">
                  Areas of Operation
                </Link>
              </div>
              <div className="company-link">
                <Link to="/company/leadership">
                  <FontAwesomeIcon className="bigicon" icon={faUsers} />
                </Link>
                <Link to="/company/leadership" role="button">
                  <Button className="company-button">
                    Executive Leadership
                  </Button>
                </Link>
              </div>
            </div>

            <div className="column">
              <div className="company-link">
                <Link to="/company/dividend-history/">
                  <div style={{ marginTop: "3.75rem" }}>
                    <CircleMoneyIcon />
                  </div>
                </Link>
                <Link to="/company/dividend-history/">Dividend History</Link>
              </div>

              <div className="company-link">
                <a href={FactSheet_2023} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon className="bigicon" icon={faChartBar} />
                </a>
                <a href={FactSheet_2023} target="_blank" rel="noreferrer">
                  Fact Sheet
                </a>
              </div>
              <div className="company-link">
                <Link to="/company/board-of-directors/">
                  <FontAwesomeIcon className="bigicon" icon={faUsers} />
                </Link>
                <Link to="/company/board-of-directors/" role="button">
                  <Button className="company-button">Board of Directors</Button>
                </Link>
              </div>
            </div>
          </section>
          <div
            className="company-parallax-overlay"
            style={{
              backgroundColor: "#23303f",
            }}
          ></div>
        </Parallax>
        <Parallax bgImage={company_bg} strength={300}>
          <section className="max-width-container page company-page company-links-layout last-company-section last-page">
            <div className="column">
              <div className="company-link">
                <Link to="/company/owners/">
                  <FontAwesomeIcon className="bigicon" icon={faUniversity} />
                </Link>
                <Link to="/company/owners/">Interest Owners</Link>
              </div>
            </div>
            <div className="column">
              <div className="company-link">
                <Link to="/company/contacts/">
                  <FontAwesomeIcon className="bigicon" icon={faBriefcase} />
                </Link>
                <Link to="/company/contacts/" role="button">
                  Business Development
                </Link>
              </div>
            </div>
          </section>
          <div
            className="company-parallax-overlay"
            style={{
              backgroundColor: "#d50000",
              opacity: "0.85",
            }}
          ></div>
        </Parallax>
      </div>
    </Layout>
  )
}
